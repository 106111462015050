import { Injectable } from '@angular/core';

import { CommonService } from '../_common.service';
import { environment } from '../../../../environments/environment';
import * as internal from 'stream';

interface GetCategoriesParameters {
  page?: number;
  limit?: number;
  id?: number;
  budget_id?: number;
  expenses_category_id?: number;
  currency_id?: number;
  is_reimburse_expense?: number;
  payment_type_id?: number;
  date_range?: string;
  start_date?: string;
  end_date?: string
}

@Injectable({
  providedIn: 'root',
})
export class ExpensesTrackerService {

  constructor(private commonService: CommonService) { }

  getCurrencies() {
    return this.commonService.getData('currency/merchant', environment.apiExpencesTrackerBaseUrl);
  }

  getPaymentTypes() {
    return this.commonService.getData('payment-type/merchant/?page=1&limit=1000', environment.apiExpencesTrackerBaseUrl);
  }
  
  getCategories() {
    return this.commonService.getData('category/merchant/?page=1&limit=1000', environment.apiExpencesTrackerBaseUrl);
  }

  getPrimaryBudget() {
    return this.commonService.getData('budget/merchant/primary-budget/?page=1&limit=1000', environment.apiExpencesTrackerBaseUrl);
  }

  getBudget() {
    return this.commonService.getData('budget/merchant/?page=1&limit=1000', environment.apiExpencesTrackerBaseUrl);
  }

  getExpenses({page = 1, limit = 10, id, budget_id, expenses_category_id, currency_id, is_reimburse_expense, payment_type_id, date_range, start_date, end_date}: GetCategoriesParameters) {
    let params = `?page=${page}&limit=${limit}`;

    if(id) {
      params += '&id=' + id;
    }
    if(budget_id) {
      params += '&budget_id=' + budget_id;
    }
    if(expenses_category_id) {
      params += '&expenses_category_id=' + expenses_category_id;
    }
    if(currency_id) {
      params += '&currency_id=' + currency_id;
    }
    if(is_reimburse_expense) {
      params += '&is_reimburse_expense=' + is_reimburse_expense;
    }
    if(payment_type_id) {
      params += '&payment_type_id=' + payment_type_id;
    }
    if(date_range) {
      params += '&date_range=' + date_range;
    }
    if(start_date) {
      params += '&start_date=' + start_date;
    }
    if(end_date) {
      params += '&end_date=' + end_date;
    }

    return this.commonService.getData('expenses/merchant/' + params, environment.apiExpencesTrackerBaseUrl);
  }

  getExpensesChart(date_range: string) {
    let params = '';

    if(date_range) {
      params += '?date_range=' + date_range;
    }
    
    return this.commonService.getData('expenses/merchant/chart/' + params, environment.apiExpencesTrackerBaseUrl);
  }


}