import { Injectable } from '@angular/core';
import { CommonService } from '../_common.service';

@Injectable({
    providedIn: 'root',
})
export class UserManagementService {

    constructor(private commonService: CommonService) { }

    getActiveUserData(page: number, page_size: number) {
        return this.commonService.getData('user/active/?page=' + page + '&page_size=' + page_size);
    }
    getInviteUserData(page: number, page_size: number) {
        return this.commonService.getData('user/pending/?page=' + page + '&page_size=' + page_size);
    }
    getAllPrivileges() {
        return this.commonService.getDataInvoice('user/all/privileges/');
      }
     
      inviteUser(data){
        return this.commonService.postData('user/invite/', data);
        // return this.commonService.postData('', data);
    }
    // cancel invitation
    deleteInvite(email: string) {
        return this.commonService.deleteData('user/' + email +'/invitation/');
      
      }
      activeeUserUpdate(data){
        return this.commonService.putData('user/privileges/', data);
        // return this.commonService.postData('', data);
    }
    inviteUserUpdate(data){
        return this.commonService.postData('user/re_invite/', data);
    }
    deleteActive(email: string) {
        return this.commonService.deleteData('user/sub_user/' + email +'/');
      
      }
      getStatus(email:string) {
        return this.commonService.getData('user/' + email +'/invitation_status/');
      }

    
}