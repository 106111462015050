<div style="overflow-x:auto;">
    <table style="border:0.7px solid #D2D2D2; border-radius: 10px;"
        class="mb-2 table table-responsive-sm table-hover table-outline">
        <thead>
            <tr class="header">
                <th *ngFor="let header of headCellRender()">{{header.head}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data | paginate: { itemsPerPage: 10, currentPage: p }" height="50">
                <td *ngFor="let head of headCellRender()">{{renderCells(item, head)}}</td>
            </tr>
            <!-- <tr *ngIf="!data.length" height="50">
                <td [attr.colspan]="headCellRender().length + 1">
                    <div class="row">
                        <div class="col-md-12 loader-area" *ngIf="isLoading">
                            Loading....
                        </div>
                        <div class="col-md-12 lo+1ader-area" *ngIf="!isLoading">
                            No Data Available.
                        </div>
                    </div>
  
                </td>
            </tr> -->
        </tbody>
    </table>
</div>
<div *ngIf="isLoading" class="d-flex justify-content-center align-items-center" style="height: 20rem;">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="!isLoading && data.length === 0" class="d-flex justify-content-center align-items-center table-no-data-div"
    style="height: 20rem;">
    <h6>No data available!</h6>
</div>

<div class="row">
    <div class="col-md-12 align-right-page">
        <br /><br />
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
</div>