import { Injectable } from '@angular/core';
import { CommonService} from '../_common.service';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  constructor(private commonService: CommonService) { }

  getCurentPackageDetails(id: number) {
    return this.commonService.getData('package/merchant-package/?u_id=' + id);
  }

  // getCurrentPackageStatus(id: number) {
  //   return this.commonService.getData('package/merchant-package/detail/?u_id=' + id);
  // }

  getUserPackages() {
    return this.commonService.getData('package/user-auth/details/');
  }

  getAdminAuthPackages(id: number) {
    return this.commonService.getData(`package/admin-auth/details/`);
  }

  requestToChangeCard() {
    return this.commonService.getData('package/request-card-update/');
  }

  getSubscriptionCardData(){
    return this.commonService.getData('package/payment-method/');
  }


  getPackageSubscriptionHistory(page:number, limit:number){
    return this.commonService.getData(`package/payments/?page=${page}&limit=${limit}`);
  }

  updatePackage(updatebody:any){
    return this.commonService.postData('package/merchant-package/', updatebody);
  }

  getTopUpdetails(){
    return this.commonService.getData('package/top-up/');
  }

  addTopUp(topupbody:any){
    return this.commonService.postData('package/merchant-package/top-up/',topupbody);

  }

  activePackage(id: number, body: any){
    return this.commonService.putData('package/activate-package/?u_id=' + id, body);
  }

}
