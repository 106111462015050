import { Injectable } from '@angular/core';
import { CommonService } from '../_common.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CheckoutSettingsService {
    constructor(
        private commonService: CommonService,
        private http: HttpClient
    ) { }

    saveConfigurations(configurations: any) {
        return this.commonService.putData('ipg/app/settings/', configurations);
    }

    getConfigurations(app_id: any) {
        return this.commonService.getData("ipg/app/settings/" + app_id + "/");
    }

    uploadImage(payload: any) {
        return this.http.post('https://spemai-file-manger-uat-dot-spemai-global.uc.r.appspot.com/api/v1/gcp/image-upload/', payload);
    }
}