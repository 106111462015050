<div class="file-upload-root">
    <div class="dropbox-wrapper" 
        [ngStyle]="{'border-color': has_errors ? '#ff3d71' : '#C6C6C6'}"
        [ngClass]="{'dropbox-wrapper-dragging': is_dragging}"
    >
        <div #fileUploadDropbox id="file-upload-dropbox" class="dropbox" [ngClass]="{'dropbox-dragging': is_dragging}">
            <img src="assets/svgs/file-upload.svg" alt="upload icon">

            <p>Drag or Click here to upload <br /> Your Files</p>

            <div class="file-list" *ngIf="files && files.length > 0">
                <div class="file-list-item" *ngFor="let file of files; let i = index">
                    <span class="filename">{{ file?.name | truncateText:28 }}</span>

                    <span class="filesize">{{ formatFileSize(file?.size) }}</span>

                    <button type="button" nbButton status="basic" size="tiny" (click)="onRemoveFile($event, i)">
                        <nb-icon icon="close-outline" status="danger" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                    </button>
                </div>
            </div>
        
            <input
                #fileUploadField
                type="file" 
                name="file" 
                multiple
                (change)="onFileChange($event)"
            >
        </div>
    </div>

    <ul class="error-wrapper" *ngIf="has_errors">
        <li *ngIf="ERRORS.MAX_COUNT.status">{{ ERRORS.MAX_COUNT.message }}</li>
        <li *ngIf="ERRORS.FORMAT.status">{{ ERRORS.FORMAT.message }}</li>
        <li *ngIf="ERRORS.MAX_LIMIT.status">{{ ERRORS.MAX_LIMIT.message }}</li>
    </ul>
</div>


