<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/pages/dashboard">Home</a></li>

    <ng-container *ngFor="let route of routes; index as i">
      <li class="breadcrumb-item" [ngClass]="route?.isActive && 'active'">
        <a *ngIf="route?.isActive">{{ route.title }}</a>
        <a (click)="navigate(route)" *ngIf="!route.isActive">{{ route.title }}</a>
      </li>
    </ng-container>
  </ol>
</nav>

