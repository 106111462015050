// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //  uat
  // apiExpencesTrackerBaseUrl: 'https://b8s1j32jr2.execute-api.ap-southeast-1.amazonaws.com/development/',
  // apiBaseUrl: 'https://onepay-backend-uat-dot-spemai-asia.el.r.appspot.com/api/',
  // apiBaseUrlIPGReport: 'https://onepay-report-uat-dot-spemai-asia.el.r.appspot.com/',
  // apiBaseUrlPaymentLink: 'https://onepay-payment-link-v3-uat-dot-spemai-asia.el.r.appspot.com/',
  // apiBaseUrlPaymentPage: 'https://onepay-payment-page-v3-uat-dot-spemai-asia.el.r.appspot.com/api/',

  // live
  apiExpencesTrackerBaseUrl: 'https://b8s1j32jr2.execute-api.ap-southeast-1.amazonaws.com/development/',
  apiBaseUrl: 'https://merchant-api-live-v2.onepay.lk/api/',
   apiBaseUrlIPGReport: 'https://onepay-report-live-dot-spemai-asia.el.r.appspot.com/',
  apiBaseUrlPaymentLink:"https://onepay-payment-link-v3-live-dot-spemai-asia.el.r.appspot.com/api/",
  apiBaseUrlPaymentPage: 'https://onepay-payment-page-v3-uat-dot-spemai-asia.el.r.appspot.com/api/',
  aws: 'https://onepayserviceimages.s3.amazonaws.com/',
  version: 2.20



};
