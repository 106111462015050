import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { ToastrComponent } from '..';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss']
})
export class CopyTextComponent implements OnInit {
  @Input() text: string;

  icon = 'copy-outline';
  tooltip_text = 'Copy';

  constructor(private toastr: ToastrComponent) { }

  ngOnInit(): void { }

  async copyToClipboard() {
    if(!this.text) {
      return;
    }

    try {
      await navigator.clipboard.writeText(this.text);
      this.icon = 'checkmark-outline';
      this.tooltip_text = 'Copied!';
      setTimeout(() => {
        this.icon = 'copy-outline';
        this.tooltip_text = 'Copy';
      }, 2000);
    } catch(error) {
      console.log('Error', error);
      this.toastr.showToast('danger', 'Error!', 'Cannot copy to clipboard.');
    }
  }

}
