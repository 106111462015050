import { Component } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
    All Rights Reserved &copy; <b><a href="https://www.onepay.lk/" target="_blank">Onepay</a></b> 2024 (Version {{app_version}})
    </span>
  `,
})
export class FooterComponent {
  app_version: any = environment.version;
}
