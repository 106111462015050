<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img class="logo-image" src="../../../../assets/images/Onepay-pg 1.png"/>
    </a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action>
      <nb-user [name]="user?.registered_business_name"
      size="small"
      picture ='{{image_base_path}}{{user?.logo_url}}'
      >
      
      </nb-user>
      

    </nb-action>

    <nb-action>
      <div class="btn-group">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false"></button>

        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" type="button" (click)="navigateToProfile()">
            Profile
          </button>

          <button class="dropdown-item" type="button" (click)="logout()">
            Logout
          </button>
        </div>
      </div>
    </nb-action>
  </nb-actions>
</div>
