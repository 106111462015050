<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <b>Filters: </b><br/><br/>
            </div>
        </div>
        <form [formGroup]="FilterForm" (ngSubmit)="generateFilterQuery()">
            <div class="row">
                <div class="col-sm-12 col-md-3 col-lg-3" *ngFor="let header of headers">
                    <div class="form-group">
                        <label [for]="header.parameter">{{header.visualizeName}}</label>
                        <span *ngIf="(header.input=='text')">
                            <input type="text" class="form-control" [id]="header.parameter" [formControlName]="header.parameter" [placeholder]="header.placeholder">
                        </span>
                        <span *ngIf="(header.input=='select')">
                            <select class="custom-select" [id]="header.parameter"  [formControlName]="header.parameter">
                                <option disabled>Please select</option>
                                <option *ngFor="let value of header.values" [value]="value.key">{{value.name}}</option>
                              </select>
                        </span>

                        <span *ngIf="(header.input=='datetime')">
                            <input type="text" class="form-control" [owlDateTime]="date_cal" [owlDateTimeTrigger]="date_cal" [id]="header.parameter" [formControlName]="header.parameter" [placeholder]="header.placeholder">
                            <owl-date-time #date_cal></owl-date-time>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <button type="submit" class="btn btn-success bg-onepay-green btn-block">Filter</button>
                </div>
            </div>
        </form>
    </div>
</div>
