import { Injectable } from '@angular/core';

import { CommonService } from '../_common.service';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {

    constructor(private commonService: CommonService) { }

    getCurrencyTypes() {
        return this.commonService.getData('ipg/db/currency/');
    }

    getTransactionVolumes() {
        return this.commonService.getData('ipg/db/get_transaction_volume/');
    }

    getPayoutData() {
        return this.commonService.getData('ipg/db/get_payout_data/');
    }

    getRecentTransactions() {
        return this.commonService.getData('ipg/db/get_recent_transaction/');
    }

    getTransactionSources() {
        return this.commonService.getData('ipg/db/get_transaction_sources/');
    }

    getTodaySales() {
        return this.commonService.getData('ipg/db/get_today_sales/');
    }

    getDailyTransactions(year: number, month: number) {
        return this.commonService.getData(`ipg/db/get_daily_transaction/?year=${year}&month=${month}`);
    }

}