import { Injectable } from '@angular/core';
import { CommonService } from '../_common.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(private commonService: CommonService) { }

    merchant_login(data) {
        return this.commonService.postData('user/merchant_login/', data);
    }

    requestPasswordResetOtp(phone_number){
        return this.commonService.getData('user/reset-password-request/?phone_number=' + phone_number );
    }

    postPwResetOtpData(data){
        return this.commonService.postData('user/reset-password-verify/', data);
    }

    postPwResetData(data){
        return this.commonService.postData('user/reset-password/', data);
    }

    changePassword(data: any) {
        return this.commonService.postData('user/change-password/', data);
    }
     //reset password-after merchant onbording
     verifyMerchantData(data){
        return this.commonService.postData('user/rest_new_password/', data);
    }
    // user management password reset
    postUserMpasswordreset(data){
        return this.commonService.postData('user/set_password/', data);
        // return this.commonService.postData('', data);
    }
    
}