<div class="row">
    <div class="col-md-12 align-right">
        <div class="btn-group export-btns" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-secondary radius-none" nbPopover="excel" nbPopoverTrigger="hint"
                (click)="exportToExcel()">
                <i class="fa fa-table" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-secondary radius-none" nbPopover="column selection"
                nbPopoverTrigger="hint" (click)="openColumnSelection(selectionModel)">
                <i class="fa fa-align-justify fa-sm"></i>
            </button>
        </div>
    </div>
</div>

<div style="overflow-x:auto;">
    <table style="border:0.7px solid #D2D2D2; border-radius: 10px;"
        class="mb-2 table table-responsive-sm table-hover table-outline">
        <thead>
            <tr class="header">
                <th *ngFor="let header of headCellRender()">{{header.head}}</th>
                <th>
                    <label style="margin-bottom: 0;">
                        Action
                        <input type="checkbox" [(ngModel)]="IsAllChecked" (change)="CheckUncheckAll()" />
                    </label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data | paginate: { itemsPerPage: 10, currentPage: p }" height="50">
                <td *ngFor="let head of headCellRender()">{{renderCells(item, head)}}</td>
                <td><input type="checkbox" [(ngModel)]="item.Selected"
                        (change)="CheckUncheckRow(item.id);CheckUncheckHeader()" /></td>
            </tr>
            <!-- <tr *ngIf="!data.length" height="50">
                <td [attr.colspan]="headCellRender().length + 1">
                    <div class="row">
                        <div class="col-md-12 loader-area" *ngIf="isLoading">
                            Loading....
                        </div>
                        <div class="col-md-12 lo+1ader-area" *ngIf="!isLoading">
                            No Data Available.
                        </div>
                    </div>

                </td>
            </tr> -->
        </tbody>
    </table>
</div>
<div *ngIf="isLoading" class="d-flex justify-content-center align-items-center" style="height: 20rem;">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="!isLoading && data.length === 0" class="d-flex justify-content-center align-items-center table-no-data-div"
    style="height: 20rem;">
    <h6>No data available!</h6>
</div>

<div class="row">
    <div class="col-md-12 align-right-page">
        <br /><br />
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
</div>

<ng-template style="border-color:rgb(23, 100, 243)" #selectionModel let-data let-ref="dialogRef">
    <nb-card style="width: 50vw; max-height: 95vh;">
        <nb-card-header class="header_text">
            <p><b>Please select rows to view in table</b></p>
        </nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="col-md-4" *ngFor="let header of allHeaders">
                    <label class="checkbox-inline"><input type="checkbox" [checked]="getSelectionCheckStatus(header)"
                            [disabled]="header['fixed']" (change)="changeSelectionStatus(header, $event)"> &nbsp;&nbsp;
                        {{header['head']}}</label>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton class="btn btn-default" (click)="cancel()">Close</button>
        </nb-card-footer>
    </nb-card>

</ng-template>