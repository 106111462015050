import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { ToastrComponent } from '../../@core/components';
import { EncriptionService } from './_encription.service';

@Injectable({
  providedIn: 'root'
})
export class PrivillageAccess implements CanActivate {
  private prop: string;

  constructor(private router: Router, private toastr: ToastrComponent,private encriptionService: EncriptionService, ) {}
  setProp(prop: string): void {
    this.prop = prop;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      console.log(next.data['pri']);
      const pri: string = next.data['pri'];
      const hasPrivillage: boolean = this.encriptionService.response_decript(localStorage.getItem("privileges")).some((innerArray:any) =>
      innerArray.some((item:any) => item.id === pri)
    );;
      if (hasPrivillage || parseInt(localStorage.getItem("sub_user"), 10) === 0) {
        return true;
      }else{
        this.toastr.showToast('danger', 'Error!', 'You can not access this');
        return false;
      }
      
  }
}
