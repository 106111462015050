import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import {OpDataTableComponent} from './op-data-table/op-data-table.component';
import { NbCardModule} from "@nebular/theme";
import { NbPopoverModule} from '@nebular/theme';
import { OpDataTable2Component } from './op-data-table2/op-data-table2.component';
import { OpDataTable3Component } from './op-data-table3/op-data-table3.component';
import { OpDataTable4Component } from './op-data-table4/op-data-table4.component';


@NgModule({  
  declarations: [OpDataTableComponent, OpDataTable2Component, OpDataTable3Component,OpDataTable3Component, OpDataTable4Component],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbPopoverModule
  ],
  exports:[OpDataTableComponent,OpDataTable2Component,OpDataTable3Component,OpDataTable4Component]
})
export class SharedDirectiveModule { }
