import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbIconModule, NbTooltipModule } from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';

import {
  LayoutService
} from './utils';
import {
  CommonService,
  EncriptionService,
  TokenInterceptor,
  UserService,
  DashboardService,
  DeveloperConfigurationsService,
  CheckoutSettingsService,
  TransactionService,
  PaymentLinkService,
  AssignDefaultAppService,
  PackageService,
  PreviousRouteService,
} from './services';
import {
  ToastrComponent,
  FilterComponent,
  TableComponent,
  BreadcrumbComponent,
  CopyTextComponent,
  FileUploadComponent,
} from './components';
import {
  AuthGuard
} from './guards';
// import { OpDataTableComponent } from './_directive/op-data-table/op-data-table.component';
// import { OpDataTable2Component } from './_directive/op-data-table2/op-data-table2.component';
// import { OpDataTable3Component } from './_directive/op-data-table3/op-data-table3.component';
// import { OpDataTable4Component } from './_directive/op-data-table4/op-data-table4.component';

export const NB_CORE_PROVIDERS = [
  LayoutService
];

export const APPLICATION_PROVIDERS = [
  CommonService,
  EncriptionService,
  TokenInterceptor,
  UserService,
  DashboardService,
  DeveloperConfigurationsService,
  CheckoutSettingsService,
  TransactionService,
  PaymentLinkService,
  AssignDefaultAppService,
  PackageService,
  PreviousRouteService,
];

export const APPLICATION_COMPONENTS = [
  ToastrComponent,
  FilterComponent,
  TableComponent,
  BreadcrumbComponent,
  CopyTextComponent,
  FileUploadComponent,
];

export const APPLICATION_GUARDS = [
  AuthGuard
];

@NgModule({
  imports: [
    ThemeModule,
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    RouterModule,
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
  ],
  exports: [
    CommonModule,
    ...APPLICATION_COMPONENTS,
  ],
  declarations: [
    ...APPLICATION_COMPONENTS,
    // OpDataTableComponent,
    // OpDataTable2Component,
    // OpDataTable3Component,
    // OpDataTable4Component,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        ...APPLICATION_PROVIDERS,
        ...APPLICATION_COMPONENTS,
        ...APPLICATION_GUARDS
      ]
    };
  }
}

