import { Component, OnInit,Input } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ToastrComponent } from '../../../@core/components';

@Component({
  selector: 'ngx-op-data-table3',
  templateUrl: './op-data-table3.component.html',
  styleUrls: ['./op-data-table3.component.scss']
})
export class OpDataTable3Component implements OnInit {

  @Input()  data: any
  @Input()  headers: any
  @Input()  isLoading = false

  allHeaders=[]
  p: number = 1;
  pageSize: number = 10
  total: number = 0;
 


  constructor(
    private toastr: ToastrComponent, private dialogService: NbDialogService
  ) { }

  ngOnInit(): void {
    if(this.headers.length) {
      this.allHeaders = this.headers
    }
  }
  headCellRender(){
    if(this.headers.length) {
      let selected_headers = this.allHeaders.filter(header => header['fixed'] || header['selected']);
      return selected_headers
    } else {
      return []
    }
  }
  getSelectionCheckStatus(row) {
    return row['fixed'] || row['selected']
  }
  changeSelectionStatus(header, event) {
    console.log(header.key,'cheack table')
  if(event.target.checked) {
    header.selected = true
    this.allHeaders[this.allHeaders.findIndex(el => el.key === header.key)] = header;
  } else {
    header.selected = false
    this.allHeaders[this.allHeaders.findIndex(el => el.key === header.key)] = header;
  }
}

}
