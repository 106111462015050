import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EncriptionService } from './_encription.service';
import 'rxjs';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
// import { stringify } from '@angular/compiler/src/util';



@Injectable({
    providedIn: 'root',
})

export class CommonService {
    constructor(private httpClient: HttpClient, private router: Router, private encriptionService: EncriptionService) { }

    postData(url: string, body: any) {
        const encripted_body = this.encriptionService.request_encript(body);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
        });
        return this.httpClient.post(environment.apiBaseUrl + url, encripted_body, { headers: httpHeaders, responseType: 'text' })
            .toPromise()
            .then((response: any) => {
                console.log('Login res', this.encriptionService.response_decript(response));
                return this.encriptionService.response_decript(response);
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                console.log('Error3333333', error);
            });
    }

    // IPG report post
    postDataIpgReport(url: string, body: any) {
        const encripted_body = this.encriptionService.request_encript(body);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
        });

        return this.httpClient.post(environment.apiBaseUrlIPGReport + url, encripted_body, { headers: httpHeaders, responseType: 'text' })
            .toPromise()
            .then((response: any) => {
        
                return this.encriptionService.response_decript(response);
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                console.log('Error3333333', error);
            });
    }
    // salitha ipg report(2024.10.02)
    postDataIpgReportEN(url: string, body: any) {
       
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.httpClient.post(environment.apiBaseUrlIPGReport + url, body, { headers: httpHeaders, responseType: 'text' })
            .toPromise()
            .then((response: any) => {
        
                return JSON.parse(response);
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                console.log('Error3333333', error);
            });
    }


        // IPG report post
        postDataPaymentLink(url: string, body: any) {
            const encripted_body = this.encriptionService.request_encript(body);
    
            const httpHeaders = new HttpHeaders({
                'Content-Type': 'text/plain',
            });
    
            return this.httpClient.post(environment.apiBaseUrlPaymentLink + url, encripted_body, { headers: httpHeaders, responseType: 'text' })
                .toPromise()
                .then((response: any) => {
                    // console.log(response, 'responsecc123')
                    // console.log('Login res', this.encriptionService.response_decript(response));
                    return this.encriptionService.response_decript(response);
                })
                .catch((error: any) => {
                    if (error.status === 401) {
                        this.logOut()
                    }
                    console.log('Error3333333', error);
                });
        }

    getData(url: string, baseUrl: string = '', encryption: boolean = true): any {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain; charset=utf-8',

        });

        let apiUrl = environment.apiBaseUrl + url;
        if (baseUrl) {
            apiUrl = baseUrl + url;
        }
        // apiUrl = 'https://webhook.site/a845b546-ddf3-493a-817e-0bb7e3b6132b';

        return this.httpClient.get(apiUrl, { headers: httpHeaders, responseType: 'text' }).toPromise()
            .then((res: any) => {
                console.log(res, 'eeeeeeeeeeeeeeeeeeeeee');
                let e = this.encriptionService.response_decript(res);

                if (encryption) {
                    return this.encriptionService.response_decript(res);
                }

                return JSON.parse(JSON.stringify(this.encriptionService.repairJson(res), null, 2));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                // console.log('Error', error);
            });
    }

    // IPG report new get
    getDataIpgReport(url: string, baseUrl: string = '', encryption: boolean = false): any {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain; charset=utf-8',

        });

        let apiUrl = environment.apiBaseUrlIPGReport + url;
        if (baseUrl) {
            apiUrl = baseUrl + url;
        }
        // apiUrl = 'https://webhook.site/a845b546-ddf3-493a-817e-0bb7e3b6132b';

        return this.httpClient.get(apiUrl, { headers: httpHeaders, responseType: 'text' }).toPromise()
            .then((res: any) => {
                console.log(res, 'eeeeeeeeeeeeeeeeeeeeee');
                let e = this.encriptionService.response_decript(res);

                if (encryption) {
                    return this.encriptionService.response_decript(res);
                }

                // return JSON.parse(JSON.stringify(this.encriptionService.repairJson(res), null, 2));
               return JSON.parse(res)
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                // console.log('Error', error);
            });
    }


        // IPG report new get
        getDataPaymentLink(url: string, baseUrl: string = '', encryption: boolean = true): any {
            const httpHeaders = new HttpHeaders({
                'Content-Type': 'text/plain; charset=utf-8',
    
            });
    
            let apiUrl = environment.apiBaseUrlPaymentLink + url;
            if (baseUrl) {
                apiUrl = baseUrl + url;
            }
            // apiUrl = 'https://webhook.site/a845b546-ddf3-493a-817e-0bb7e3b6132b';
    
            return this.httpClient.get(apiUrl, { headers: httpHeaders, responseType: 'text' }).toPromise()
                .then((res: any) => {
                    console.log(res, 'eeeeeeeeeeeeeeeeeeeeee');
                    let e = this.encriptionService.response_decript(res);
    
                    if (encryption) {
                        return this.encriptionService.response_decript(res);
                    }
    
                    return JSON.parse(JSON.stringify(this.encriptionService.repairJson(res), null, 2));
                })
                .catch((error: any) => {
                    if (error.status === 401) {
                        this.logOut()
                    }
                    // console.log('Error', error);
                });
        }



                // IPG report new get
                getDataPaymentPage(url: string, baseUrl: string = '', encryption: boolean = true): any {
                    const httpHeaders = new HttpHeaders({
                        'Content-Type': 'text/plain; charset=utf-8',
            
                    });
            
                    let apiUrl = environment.apiBaseUrlPaymentPage + url;
                    if (baseUrl) {
                        apiUrl = baseUrl + url;
                    }
                    // apiUrl = 'https://webhook.site/a845b546-ddf3-493a-817e-0bb7e3b6132b';
            
                    return this.httpClient.get(apiUrl, { headers: httpHeaders, responseType: 'text' }).toPromise()
                        .then((res: any) => {
                            console.log(res, 'eeeeeeeeeeeeeeeeeeeeee');
                            let e = this.encriptionService.response_decript(res);
            
                            if (encryption) {
                                return this.encriptionService.response_decript(res);
                            }
            
                            return JSON.parse(JSON.stringify(this.encriptionService.repairJson(res), null, 2));
                        })
                        .catch((error: any) => {
                            if (error.status === 401) {
                                this.logOut()
                            }
                            // console.log('Error', error);
                        });
                }
        
    

    // view transaction 
    getDataIpgReportView(url: string, baseUrl: string = '', encryption: boolean = true): any {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain; charset=utf-8',

        });

        let apiUrl = environment.apiBaseUrlIPGReport + url;
        if (baseUrl) {
            apiUrl = baseUrl + url;
        }
       

        return this.httpClient.get(apiUrl, { headers: httpHeaders, responseType: 'text' }).toPromise()
            .then((res: any) => {
                console.log(res, 'eeeeeeeeeeeeeeeeeeeeee');
                let e = this.encriptionService.response_decript(res);

                if (encryption) {
                    return this.encriptionService.response_decript(res);
                }

                return JSON.parse(JSON.stringify(this.encriptionService.repairJson(res), null, 2));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                // console.log('Error', error);
            });
    }










    putData(url: string, body: any) {
        const encripted_body = this.encriptionService.request_encript(body);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
        });

        return this.httpClient.put(environment.apiBaseUrl + url, encripted_body, { headers: httpHeaders, responseType: 'text' })
            .toPromise()
            .then((response: any) => {

                return this.encriptionService.response_decript(response);
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                console.log('Error', error);
            });
    }

    deleteData(url: string) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
        });

        return this.httpClient.delete(environment.apiBaseUrl + url, { headers: httpHeaders, responseType: 'text' })
            .toPromise()
            .then((response: any) => {
                return this.encriptionService.response_decript(response);
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                console.log('Error', error);
            });
    }
    deleteDataBody(url: string, body: any): Promise<any> {
        const encryptedBody = this.encriptionService.request_encript(body);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
        });

        return this.httpClient.delete(environment.apiBaseUrl + url, { headers: httpHeaders, body: encryptedBody, responseType: 'text' })
            .toPromise()
            .then((response: any) => this.encriptionService.response_decript(response))
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut();
                }
                console.error('Error during DELETE request:', error);
                // Rethrow the error to propagate it further or handle it according to your needs.
                throw error;
            });
    }


    refreshToken(token: string) {
        const body = {
            'refresh': token,
        };

        return this.httpClient.post(environment.apiBaseUrl + 'token/refresh/', body).toPromise()
            .then(res => {
                return res;
            })
            .catch(error => {
                console.warn('session expired', error);
                this.router.navigate(['/login']);
            });
    }

    getUserPayload() {
        const token = this.getToken();
        if (token && token != 'undefined') {
            const userPayload = atob(token.split('.')[1]);

            return JSON.parse(userPayload);
        } else {
            return null;
        }
    }

    isLoggedIn() {
        const userPayload = this.getUserPayload();
        if (userPayload) {
            return userPayload.exp > Date.now() / 1000;
        } else {
            return false;
        }
    }

    getToken() {
        try {
            let token = localStorage.getItem('xsrft');

            if (token) {
                return token
            }

            this.router.navigate(['/authentication/login']);
            return;
        } catch (error) {
            this.router.navigate(['/authentication/login']);
            return;
        }

    }

    logOut() {
        localStorage.removeItem('xsrft');
        this.router.navigate(['/authentication/login']);
    }



    getDataBase64(url): any {
        const self = this;

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
        });

        return this.httpClient.get(environment.apiBaseUrl + url, { headers: httpHeaders, responseType: 'text' }).toPromise()
            .then((res: any) => {
                // console.log(this.encriptionService.response_decript(res));
                //let data= this.encriptionService.base64Decoder(res);

                //  let data= this.encriptionService.base64Decoder(res);
                let data2 = this.encriptionService.response_decript2(res);
                let data = this.encriptionService.base64Decoder(data2);

                let data3 = JSON.parse(data);
                //  console.log(data3)
                return data3;
            })
            .catch((error: any) => {
                if (error.status == 401) {
                    this.logOut()
                }
                console.log('ERROR', error);
            });
    }
    //   invoice

    getDataInvoice(url: string, baseUrl: string = '', encryption: boolean = false): any {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain; charset=utf-8',

        });

        let apiUrl = environment.apiBaseUrl + url;
        if (baseUrl) {
            apiUrl = baseUrl + url;
        }
        return this.httpClient.get(apiUrl, { headers: httpHeaders, responseType: 'text' }).toPromise()
            .then((res: any) => {
                // console.log(res,'eeeeeeeeeeeeeeeeeeeeeebbbbb'); 
                let e = this.encriptionService.response_decript(res);

                if (encryption) {
                    return this.encriptionService.response_decript(res);
                }

                return JSON.parse(JSON.stringify(this.encriptionService.repairJson(res), null, 2));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                // console.log('Error', error);
            });
    }


    postDataInvoice(url: string, body: any) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.httpClient.post(environment.apiBaseUrl + url, body, { headers: httpHeaders, responseType: 'text' })
            .toPromise()
            .then((response: any) => {
                console.log(response, 'response newwww')
                return this.encriptionService.response_decript(response);
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                console.log('Error', error);
            });
    }


    putDataInvoice(url: string, body: any) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.httpClient.put(environment.apiBaseUrl + url, body, { headers: httpHeaders, responseType: 'text' })
            .toPromise()
            .then((response: any) => {

                return this.encriptionService.response_decript(response);
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut()
                }
                console.log('Error', error);
            });
    }

    getData2(url: string, baseUrl: string = '', encryption: boolean = true): any {
        // let httpHeaders = new HttpHeaders({
        //     'Content-Type': 'text/plain; charset=utf-8',

        // });

        let apiUrl = environment.apiBaseUrl + url;
        if (baseUrl) {
            apiUrl = baseUrl + url;
        }

        return this.httpClient.get(apiUrl, { responseType: 'text' })
            .toPromise()
            .then((res: any) => {
                console.log(res, 'Response');
                let decryptedRes = this.encriptionService.response_decript(res);

                if (encryption) {
                    return decryptedRes;
                }

                return JSON.parse(JSON.stringify(this.encriptionService.repairJson(res), null, 2));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logOut();
                }
                console.error('Error', error);
                throw error; // Throw the error again to propagate it to the caller
            });
    }

    // download as xml 01
    // async postDataIpgReportXml(url: string, body: any): Promise<void> {
    //     const encryptedBody = this.encriptionService.request_encript(body);
    
    //     const httpHeaders = {
    //         'Content-Type': 'text/plain',
    //         'Authorization': `Bearer ${this.getToken()}`
    //     };
    
    //     try {
    //         const response = await fetch(environment.apiBaseUrlIPGReport + url, {
    //             method: 'POST',
    //             headers: httpHeaders,
    //             body: encryptedBody,
    //         });
    
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         console.log(response,'rty')
    
    //         const responseBlob = await response.blob();
    //         const blob = new Blob([responseBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    //         console.log(blob, 'response blob');
    
    //         const downloadUrl = window.URL.createObjectURL(blob);
    
    //         const a = document.createElement('a');
    //         a.style.display = 'none';
    //         a.href = downloadUrl;
    //         a.download = 'report.xlsx';
    //         document.body.appendChild(a);
    //         a.click();
    //         window.URL.revokeObjectURL(downloadUrl);
    //         document.body.removeChild(a);
    //     } catch (error) {
    //         console.error('There was an error!', error);
    //     }
    // }





// download as xml 02    

async postDataIpgReportXml(url: string, body: any): Promise<void> {
    console.log(body['start_date'],'bodybody')
    const encryptedBody = this.encriptionService.request_encript(body);

    const httpHeaders = {
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${this.getToken()}`
    };

    try {
        const response = await fetch(environment.apiBaseUrlIPGReport + url, {
            method: 'POST',
            headers: httpHeaders,
            body: encryptedBody,
        });
        console.log(this.encriptionService.response_decript(response),'responseccccreport')

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Read the response stream and convert it to a Uint8Array
        const reader = response.body.getReader();
        const chunks = [];
        let done, value;

        while ({ done, value } = await reader.read(), !done) {
            chunks.push(value);
        }

        // Combine all Uint8Array chunks into one Uint8Array
        let combinedChunks = new Uint8Array(chunks.reduce((acc, val) => acc.concat(Array.from(val)), []));

        // Create a Blob from the Uint8Array with the correct MIME type
        const blob = new Blob([combinedChunks], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        // Create a URL for the Blob and download the file
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        const startDate = body['start_date'] ? body['start_date'].replace(/[:\/]/g, '/') : 'unknown_start';
        const endDate = body['end_date'] ? body['end_date'].replace(/[:\/]/g, '/') : 'unknown_end';
        a.download = `Settlement report ${startDate} to ${endDate}.xlsx`;
        // const formatDate = (date: string) => {
        //     const [year, month, day] = date.split('-');
        //     return `${day}/${month}/${year}`;
        // };
        // const startDate = body['start_date'] ? formatDate(body['start_date']) : 'unknown_start';
        // const endDate = body['end_date'] ? formatDate(body['end_date']) : 'unknown_end';
        // a.download = `Settlement report ${startDate} to ${endDate}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
    } catch (error) {
        console.error('There was an error!', error);
    }
}

// download as xml 03 transaction history report
async postDataIpgReportXmlTransactionHistory(url: string, body: any): Promise<void> {
    // console.log(body['start_date'],'bodybody')
    // const encryptedBody = this.encriptionService.request_encript(body);

    const httpHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
    };

    try {
        const response = await fetch(environment.apiBaseUrlIPGReport + url, {
            method: 'POST',
            headers: httpHeaders,
            body: JSON.stringify(body),
        });
        // console.log(this.encriptionService.response_decript(response),'responseccccreport')

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Read the response stream and convert it to a Uint8Array
        const reader = response.body.getReader();
        const chunks = [];
        let done, value;

        while ({ done, value } = await reader.read(), !done) {
            chunks.push(value);
        }

        // Combine all Uint8Array chunks into one Uint8Array
        let combinedChunks = new Uint8Array(chunks.reduce((acc, val) => acc.concat(Array.from(val)), []));

        // Create a Blob from the Uint8Array with the correct MIME type
        const blob = new Blob([combinedChunks], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        // Create a URL for the Blob and download the file
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
       
        a.download = `Transactions history report.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
    } catch (error) {
        console.error('There was an error!', error);
    }
}




}
