import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { BreadcrumbType } from '../../models';
import { PreviousRouteService } from '../../services';

@Component({
  selector: 'ngx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() routes: BreadcrumbType[] = [];

  constructor(private router: Router, private prevRouteService: PreviousRouteService) { }

  ngOnInit(): void {
     // get current route
     // console.log(this.routes);
  }

  navigate(route: BreadcrumbType): void {
    if(route.goBack) {
      if(this.prevRouteService.getPreviousUrl() === this.prevRouteService.getCurrentUrl()) {
        // console.log(this.prevRouteService.getPreviousUrl(), this.prevRouteService.getCurrentUrl());
        window.history.back();
      } else {
        this.router.navigateByUrl(this.prevRouteService.getPreviousUrl());
      }

      return;
    }
    this.router.navigateByUrl(route.link);
  }


}
