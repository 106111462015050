import { Injectable } from '@angular/core';
import { CommonService } from '../_common.service';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(private commonService: CommonService) { }

    getBanks(page: number, page_size: number) {
        return this.commonService.getData('bank/?page=' + page + '&limit' + page_size);
    }

    addBank(body: any){
        return this.commonService.postData('bank/', body);
    }

    updateBank(body: any, id: number){
        return this.commonService.putData('bank/?id=' + id, body);
    }

    deleteBank(id: number){
      return this.commonService.deleteData('bank/?id=' + id);
    }
}
