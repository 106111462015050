import { Injectable } from '@angular/core';
import { CommonService} from '../_common.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private commonService: CommonService) { }

    getMerchantDetails() {
        return this.commonService.getData('merchant/merchant_full_data_get/');
    }

    getCoordinates(url: string, key: string) {
        // return this.commonService.getDataWithoutEncrypt('https://maps.googleapis.com/maps/api/geocode/json?address='+url+'&key='+key);
    }

    updateProfilePic(data: any){
          return this.commonService.putData('merchant/merchant_profile_data_update/', data);
    }

    // updateMerchantData(data: any) { }

}
