import { Injectable } from '@angular/core';
import { CommonService } from '../_common.service';

@Injectable({
providedIn: 'root'
})
export class AssignDefaultAppService {

    constructor(private commonService: CommonService) { }

    getDefaultApp() {
        return this.commonService.getData('ipg/custom-gateway/default-app/');
    }

    addDefaultApp(body: any) {
        return this.commonService.postData('ipg/custom-gateway/default-app/', body);
    }

    updateDefaultApp(body: any) {
        return this.commonService.putData('ipg/custom-gateway/default-app/', body);
    }

}