<table class="mb-2 table table-responsive-sm table-hover table-outline">
    <thead class="thead-light">
      <tr class="table-head">
        <th>ID</th>
        <th>FIRST NAME</th>
        <th>LAST NAME</th>
        <th>EMAIL</th>
        <th>PHONE NUMBER</th>
        <th>CUSTOMER BALANCE</th>
        <th>ACTION</th>
      </tr>
    </thead>

    <tbody>
      <tr class="td-body">
          <td>ddd</td>
          <td>ddd</td>
          <td>ddd</td>
          <td>ddd</td>
          <td>ddd</td>
          <td>ddd</td>
          <td>ddd</td>
      </tr>
      <tr class="td-body">
        <td>ddd</td>
        <td>ddd</td>
        <td>ddd</td>
        <td>ddd</td>
        <td>ddd</td>
        <td>ddd</td>
        <td>ddd</td>
    </tr>
    </tbody>
  </table>