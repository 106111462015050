import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CommonService } from '../services/_common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private commonService: CommonService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (!this.commonService.isLoggedIn()) {
      //   console.log('logged')
      // this.commonService.logOut();
      this.router.navigate(['authentication/login']);
      return false;
    }

    return true;
  }

}
