import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { CommonService } from './_common.service';
import { Observable } from 'rxjs';
import { UserManagementService } from './app_services/user-management.service'

@Injectable()

export class TokenInterceptor implements HttpInterceptor {
  constructor(public common: CommonService, public userManagementService: UserManagementService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urlPattern = /user\/([^/]+)\/invitation_status/;
  
    if (request.url.includes('user/merchant_login/')) {
      return next.handle(request);
    }

    if (request.url.includes('token/refresh/')) {
      return next.handle(request);
    }

    if (request.url.includes('user/rest_new_password/')) {
      return next.handle(request);
    }

    if (request.url.includes('user/reset-password-request/?phone_number=')) {
      return next.handle(request);
    }

    if (request.url.includes('r/reset-password-verify/')) {
      return next.handle(request);
    }

    if (request.url.includes('user/reset-password/')) {
      return next.handle(request);
    }
    if (request.url.includes('user/set_password/')) {
      return next.handle(request);
    }
    // if (request.url.includes(`user/${this.userManagementService.getStatus('')}/invitation_status/`)) {
    //   return next.handle(request);
    // }
    if (urlPattern.test(request.url)) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.common.getToken()}`
      }
    });
    return next.handle(request);
  }
}