import { Injectable } from '@angular/core';

import { CommonService } from '../_common.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentLinkService {

    constructor(private commonService: CommonService) { }

    getPaymantLinks(page: number, pageSize: number, referenceNumber: string, status: string) {
        let params = "page=" + page + "&limit=" + pageSize;

        if(referenceNumber) {
          params += "&reference=" + referenceNumber;
        }
        if(status) {
          params += "&status=" + status;
        }
    
        return this.commonService.getDataPaymentLink('ipg/payment-link/?' + params);
    }

    createPaymentLink(body: any) {
        return this.commonService.postDataPaymentLink('ipg/payment-link/', body);
    }

    disablePaymentLink(body: any) {
      return this.commonService.postData('ipg/payment_link_disable/', body);
    }

}