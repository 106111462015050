import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FilterHeaders } from "../../models";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment'

@Component({
  selector: 'ngx-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewInit  {
  @Input()  headers: FilterHeaders[];
  @Output() exportQuery = new EventEmitter<string>();
  FilterForm: FormGroup;

  constructor(private fb: FormBuilder) { 
    this.FilterForm = fb.group({});
  }

  ngOnInit(): void {
    this.headers.forEach((key : any, val: any) => {
      this.FilterForm.addControl(key['parameter'], new FormControl(key['defaultValue']));
    })
  }

  ngAfterViewInit() {
    this.generateFilterQuery();
}

  generateFilterQuery(): void {
    let form_values = this.FilterForm.value;
    let query_string = "";

    for (var key in form_values) {
      if (form_values.hasOwnProperty(key)) {
        let form_value = form_values[key];
        let header = this.headers.find(element => element['parameter'] == key);

        if(header.input == "datetime") {
          form_value = moment(form_value).format('YYYY-MM-DD HH:mm:ss')
          form_value=='Invalid date' ? (form_value = "") : form_value
        }

        query_string = query_string + key + "=" + this.validateNull(form_value) + "&"
      }
    }

    query_string = query_string.slice(0, -1);
    this.pushQuery(query_string)
  }

  validateNull(value): string {
    if (value) {
      return value.toString()
    }

    return 'None'
  }

  pushQuery(value: string) {
    this.exportQuery.emit(value);
  }

}
