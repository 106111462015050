import { Injectable } from '@angular/core';

import { CommonService } from '../_common.service';

@Injectable({
    providedIn: 'root',
})
export class DeveloperConfigurationsService {

    constructor(private commonService: CommonService) { }

    getDeveloperDetails() {
        return this.commonService.getData('ipg/ipg/developer/');
    }

    getIPGApps() {
        return this.commonService.getData('ipg/ipg/app/');
    }

    getAppsById(id: number) {
        return this.commonService.getData('ipg/ipg/app/?app_id=' + id);
    }

    updateDeveloperDetails(body: any) {
        return this.commonService.putData('ipg/ipg/developer/', body);
    }

    refreshAppToken(body: any) {
        return this.commonService.putData('ipg/ipg/app-token-modification/', body);
    }

    deactivateApp(body: any) {
        return this.commonService.putData('ipg/ipg/app-disable/', body);
    }

    reactivateApp(body: any) {
        return this.commonService.postData('ipg/ipg/app-request-active/', body );
    }

    getIpgProviders() {
        return this.commonService.getData('ipg/ipg/app-providers/');
    }

    addApp(body: any) {
        return this.commonService.postData('ipg/ipg/app/', body);
    }

    updateApp(body: any) {
        return this.commonService.putData('ipg/ipg/app/', body);
    }

    requestGoLive(body: any) {
        return this.commonService.postData('ipg/ipg/app-request-to-live/', body);
    }

    requestGoUpdate(body: any) {
        return this.commonService.postData('ipg/ipg/app-modification/', body);
    }

}